export enum PHONE_PERSPECTIVE {
  SCREEN_ON = 'SCREEN_ON',
  FRONT = 'FRONT',
  BACK = 'BACK',
  LATERAL = 'LATERAL',
}

export const PHONE_PERSPECTIVE_DESCRIPTION: { [key in PHONE_PERSPECTIVE]: string } = {
  [PHONE_PERSPECTIVE.SCREEN_ON]: 'Tela Ligada',
  [PHONE_PERSPECTIVE.FRONT]: 'Tela Desligada',
  [PHONE_PERSPECTIVE.BACK]: 'Traseira',
  [PHONE_PERSPECTIVE.LATERAL]: 'Laterais',
};

export type PHONE_PERSPECTIVE_LOWER = Lowercase<PHONE_PERSPECTIVE>;

type ToCamelCase<S extends string> = S extends `${infer T}_${infer U}`
  ? `${Lowercase<T>}${Capitalize<ToCamelCase<U>>}`
  : Lowercase<S>;

export type PHONE_PERSPECTIVE_CAMELCASE = ToCamelCase<PHONE_PERSPECTIVE>;
