import axios from 'axios';

import { env } from './env';

const headers = {
  'Accept-Language': 'pt-BR',
  country: 'BR',
  currency: 'BRL',
};

export const tradeInApi = axios.create({
  baseURL: env.TRADE_IN_BACKEND_ADDRESS || 'https://api.tradein.staging.doji.com.br',
  headers: headers,
});
