import { ROUTES } from './routes';

type NavigationFlow = {
  [key in ROUTES]: {
    previous: ROUTES[];
    next: ROUTES[];
  };
};

export const NAVIGATION_FLOW: NavigationFlow = {
  //AUTH
  [ROUTES.LOGIN]: {
    previous: [],
    next: [ROUTES.ONLY_ADMIN, ROUTES.OPERATOR_PORTAL, ROUTES.SIGN_UP],
  },
  [ROUTES.SIGN_UP]: {
    previous: [],
    next: [ROUTES.SEARCH],
  },
  [ROUTES.MAGIC_LINK_CONFIRMATION]: {
    previous: [],
    next: [ROUTES.OPERATOR_PORTAL],
  },
  [ROUTES.FORGOT_PASSWORD]: {
    previous: [ROUTES.LOGIN],
    next: [ROUTES.OPERATOR_PORTAL],
  },
  [ROUTES.ONLY_ADMIN]: {
    previous: [],
    next: [],
  },
  [ROUTES.SSO_CALLBACK]: {
    previous: [],
    next: [],
  },

  //STORE OPERATOR
  [ROUTES.OPERATOR_PORTAL]: {
    previous: [],
    next: [
      ROUTES.SEARCH,
      ROUTES.OPERATOR_PORTAL_INVITE,
      ROUTES.OPERATOR_PORTAL_DASHBOARD,
      ROUTES.OPERATOR_PORTAL_TRADE_INS,
    ],
  },
  [ROUTES.OPERATOR_PORTAL_INVITE]: {
    previous: [ROUTES.OPERATOR_PORTAL],
    next: [],
  },
  [ROUTES.OPERATOR_PORTAL_DASHBOARD]: {
    previous: [ROUTES.OPERATOR_PORTAL],
    next: [],
  },
  [ROUTES.OPERATOR_PORTAL_TRADE_INS]: {
    previous: [ROUTES.OPERATOR_PORTAL],
    next: [],
  },

  //GRADING
  [ROUTES.SEARCH]: {
    previous: [],
    next: [ROUTES.GRADING_SELECT_SPECS],
  },
  [ROUTES.GRADING_SELECT_SPECS]: {
    previous: [ROUTES.SEARCH],
    next: [ROUTES.GRADING_QUESTIONS],
  },
  [ROUTES.GRADING_QUESTIONS]: {
    previous: [ROUTES.GRADING_SELECT_SPECS],
    next: [ROUTES.GRADING_NEW_PRODUCT_BUY, ROUTES.TRADE_IN_UNAVAILABLE, ROUTES.GRADING_SUMMARY],
  },
  [ROUTES.GRADING_NEW_PRODUCT_BUY]: {
    previous: [ROUTES.GRADING_QUESTIONS, ROUTES.TRADE_IN_CONFIRM],
    next: [ROUTES.GRADING_NEW_PRODUCT_SELECT_SPECS, ROUTES.GRADING_NEW_PRODUCT_BUY_PRICE, ROUTES.TRADE_IN_CONFIRM],
  },
  [ROUTES.GRADING_NEW_PRODUCT_SELECT_SPECS]: {
    previous: [ROUTES.GRADING_NEW_PRODUCT_BUY],
    next: [ROUTES.GRADING_SUMMARY, ROUTES.GRADING_NEW_PRODUCT_BUY_PRICE, ROUTES.GRADING_PRICING_ANNOUNCEMENT],
  },
  [ROUTES.GRADING_NEW_PRODUCT_BUY_PRICE]: {
    previous: [ROUTES.GRADING_NEW_PRODUCT_BUY, ROUTES.GRADING_NEW_PRODUCT_SELECT_SPECS],
    next: [ROUTES.GRADING_SUMMARY, ROUTES.GRADING_PRICING_ANNOUNCEMENT],
  },
  [ROUTES.GRADING_SUMMARY]: {
    previous: [ROUTES.GRADING_QUESTIONS, ROUTES.GRADING_NEW_PRODUCT_SELECT_SPECS, ROUTES.GRADING_NEW_PRODUCT_BUY_PRICE],
    next: [
      ROUTES.GRADING_CUSTOMER_FORMS,
      ROUTES.TRADE_IN_UNAVAILABLE,
      ROUTES.TRADE_IN_GIVE_UP,
      ROUTES.TRADE_IN_CONFIRM,
      ROUTES.GRADING_PRICING_ANNOUNCEMENT,
    ],
  },
  [ROUTES.GRADING_CUSTOMER_FORMS]: {
    previous: [ROUTES.GRADING_SUMMARY],
    next: [ROUTES.GRADING_CHECK_IMEI, ROUTES.SEARCH],
  },
  [ROUTES.GRADING_CHECK_IMEI]: {
    previous: [ROUTES.GRADING_CUSTOMER_FORMS],
    next: [ROUTES.GRADING_PHONE_PHOTOS, ROUTES.SEARCH],
  },
  [ROUTES.GRADING_PHONE_PHOTOS]: {
    previous: [ROUTES.GRADING_CHECK_IMEI],
    next: [ROUTES.FUNCT_GRADING_STEP_BY_STEP, ROUTES.AI_ANALYZING],
  },
  [ROUTES.GRADING_PRICING_ANNOUNCEMENT]: {
    previous: [ROUTES.GRADING_SUMMARY, ROUTES.GRADING_NEW_PRODUCT_SELECT_SPECS, ROUTES.GRADING_NEW_PRODUCT_BUY_PRICE],
    next: [ROUTES.TRADE_IN_GIVE_UP, ROUTES.GRADING_CUSTOMER_FORMS, ROUTES.TRADE_IN_CONFIRM],
  },

  //FUNCT GRADING
  [ROUTES.FUNCT_GRADING_STEP_BY_STEP]: {
    previous: [ROUTES.GRADING_PHONE_PHOTOS],
    next: [ROUTES.FUNCT_GRADING_SKIP, ROUTES.FUNCT_GRADING_RESULT],
  },
  [ROUTES.FUNCT_GRADING_RESULT]: {
    previous: [ROUTES.FUNCT_GRADING_STEP_BY_STEP],
    next: [ROUTES.AI_ANALYZING],
  },
  [ROUTES.FUNCT_GRADING_SKIP]: {
    previous: [ROUTES.FUNCT_GRADING_STEP_BY_STEP],
    next: [ROUTES.AI_ANALYZING],
  },

  //AI VERIFICATION
  [ROUTES.AI_ANALYZING]: {
    previous: [ROUTES.FUNCT_GRADING_RESULT, ROUTES.GRADING_PHONE_PHOTOS],
    next: [ROUTES.AI_DEFECT_DETECTION, ROUTES.TRADE_IN_DOWNGRADE, ROUTES.TRADE_IN_CONFIRM],
  },
  [ROUTES.AI_DEFECT_DETECTION]: {
    previous: [ROUTES.FUNCT_GRADING_RESULT, ROUTES.GRADING_PHONE_PHOTOS],
    next: [ROUTES.TRADE_IN_CONFIRM, ROUTES.TRADE_IN_DOWNGRADE],
  },

  //TRADE-IN
  [ROUTES.TRADE_IN_DOWNGRADE]: {
    previous: [],
    next: [ROUTES.TRADE_IN_UNAVAILABLE, ROUTES.TRADE_IN_CONFIRM, ROUTES.SEARCH],
  },
  [ROUTES.TRADE_IN_CONFIRM]: {
    previous: [ROUTES.TRADE_IN_DOWNGRADE, ROUTES.GRADING_NEW_PRODUCT_BUY, ROUTES.GRADING_NEW_PRODUCT_BUY_PRICE],
    next: [ROUTES.TRADE_IN_CONTRACT, ROUTES.TRADE_IN_AUCTION],
  },
  [ROUTES.TRADE_IN_CONTRACT]: {
    previous: [],
    next: [ROUTES.TRADE_IN_SUCCESS],
  },
  [ROUTES.TRADE_IN_SUCCESS]: {
    previous: [],
    next: [ROUTES.SEARCH],
  },
  [ROUTES.TRADE_IN_UNAVAILABLE]: {
    previous: [],
    next: [ROUTES.SEARCH],
  },
  [ROUTES.TRADE_IN_GIVE_UP]: {
    previous: [],
    next: [ROUTES.SEARCH],
  },

  //TRADE-IN AUCTION
  [ROUTES.TRADE_IN_AUCTION]: {
    previous: [ROUTES.TRADE_IN_CONFIRM],
    next: [ROUTES.TRADE_IN_AUCTION_BUYER_NOT_FOUND, ROUTES.TRADE_IN_AUCTION_SUCCESS],
  },
  [ROUTES.TRADE_IN_AUCTION_SUCCESS]: {
    previous: [],
    next: [ROUTES.TRADE_IN_CONTRACT],
  },
  [ROUTES.TRADE_IN_AUCTION_BUYER_NOT_FOUND]: {
    previous: [],
    next: [ROUTES.TRADE_IN_CONTRACT],
  },

  //CUSTOMER
  [ROUTES.CUSTOMER_SEARCH]: {
    previous: [],
    next: [ROUTES.CUSTOMER_TRADE_IN_UNAVAILABLE, ROUTES.CUSTOMER_SELECT_SPECS],
  },
  [ROUTES.CUSTOMER_SELECT_SPECS]: {
    previous: [ROUTES.CUSTOMER_SEARCH],
    next: [ROUTES.CUSTOMER_QUESTIONS],
  },
  [ROUTES.CUSTOMER_QUESTIONS]: {
    previous: [ROUTES.CUSTOMER_SELECT_SPECS],
    next: [ROUTES.CUSTOMER_SUMMARY, ROUTES.CUSTOMER_TRADE_IN_UNAVAILABLE],
  },
  [ROUTES.CUSTOMER_SUMMARY]: {
    previous: [ROUTES.CUSTOMER_QUESTIONS],
    next: [ROUTES.CUSTOMER_CUSTOMER_FORMS, ROUTES.CUSTOMER_REFUSE_TRADE_IN, ROUTES.CUSTOMER_REFUSE_TRADE_IN],
  },
  [ROUTES.CUSTOMER_CUSTOMER_FORMS]: {
    previous: [ROUTES.CUSTOMER_SUMMARY],
    next: [ROUTES.CUSTOMER_SUCCESS, ROUTES.CUSTOMER_SEARCH],
  },
  [ROUTES.CUSTOMER_SUCCESS]: {
    previous: [],
    next: [],
  },
  [ROUTES.CUSTOMER_TRADE_IN_UNAVAILABLE]: {
    previous: [],
    next: [ROUTES.CUSTOMER_SEARCH],
  },
  [ROUTES.CUSTOMER_REFUSE_TRADE_IN]: {
    previous: [],
    next: [ROUTES.CUSTOMER_SEARCH],
  },

  // Session
  [ROUTES.SESSION_SEARCH]: {
    previous: [],
    next: [
      ROUTES.SESSION_SELECT_SPECS,
      ROUTES.SESSION_NOT_FOUND,
      ROUTES.SESSION_QUESTIONS,
      ROUTES.SESSION_IMEI_CHECK,
      ROUTES.SESSION_SUCCESS,
    ],
  },
  [ROUTES.SESSION_SELECT_SPECS]: {
    previous: [ROUTES.SESSION_SEARCH],
    next: [ROUTES.SESSION_QUESTIONS],
  },
  [ROUTES.SESSION_QUESTIONS]: {
    previous: [ROUTES.SESSION_SELECT_SPECS],
    next: [ROUTES.SESSION_IMEI_CHECK, ROUTES.SESSION_SUCCESS],
  },
  [ROUTES.SESSION_IMEI_CHECK]: {
    previous: [ROUTES.SESSION_QUESTIONS],
    next: [ROUTES.SESSION_SUCCESS],
  },
  [ROUTES.SESSION_NOT_FOUND]: {
    previous: [ROUTES.SESSION_SEARCH],
    next: [],
  },
  [ROUTES.SESSION_SUCCESS]: {
    previous: [ROUTES.SESSION_IMEI_CHECK],
    next: [],
  },
  [ROUTES.BLANK_PAGE]: {
    previous: [],
    next: [],
  },
};
