import { LOCALE } from '@/constants/locales';

const handleCurrency = (locale?: LOCALE) => {
  switch (locale) {
    default:
      return 'BRL';
  }
};

export const formatCurrency = (value: number, locale?: LOCALE) => {
  return new Intl.NumberFormat(locale || LOCALE.BR, {
    style: 'currency',
    currency: handleCurrency(locale),
  }).format(value);
};
