import { useMemo } from 'react';

import { flatten } from 'ramda';

import { usePricingContext } from '@/context/use-pricing-provider';
import { useTradeInContext } from '@/context/use-trade-in-provider';
import { useUserAuth } from '@/context/user-auth-provider';

import { BoostBid } from '@@/Pricing/entities/boost-bid';
import { getStoreBoostedPrice } from '@@/Pricing/utils';

export const useEventsParams = () => {
  const { storeData, userData } = useUserAuth();
  const { itemData, tradeInData, productName } = useTradeInContext();
  const { bestBidData } = usePricingContext();

  const isBoostedPrice = storeData?.acceptBoost && tradeInData?.pricing?.acceptBoost;

  const userParam = useMemo(
    () => ({
      userId: userData?.userId ?? null,
    }),
    [userData?.userId]
  );
  const storeParam = useMemo(
    () => ({ storeId: storeData?.id, isTestStore: storeData?.isTest ?? null }),
    [storeData?.id, storeData?.isTest]
  );

  const productParam = useMemo(
    () => ({
      productTitle: productName,
      productId: tradeInData?.productId ?? itemData?.product?.productId ?? null,
    }),
    [tradeInData, itemData]
  );

  const mappedBoosts = bestBidData?.boosts?.map((boost: BoostBid) => ({
    [boost?.sponsorshipId]: getStoreBoostedPrice(bestBidData, boost.sponsorshipId),
  }));
  const boostObj = Object.assign({}, ...flatten(mappedBoosts ?? []));

  const pricesParam = useMemo(
    () => ({
      ...boostObj,
      basePrice: bestBidData?.storePrice,
      selectedOption: tradeInData?.pricing?.sponsorshipId,
    }),
    [bestBidData, isBoostedPrice]
  );

  const tradeInParam = useMemo(
    () => ({
      tradeInId: tradeInData?.id ?? null,
      itemDeflator: itemData?.grading?.deflators ?? null,
    }),
    [tradeInData?.id, itemData?.grading?.deflators]
  );

  const surveyItemParam = useMemo(() => (itemData?.id ? { itemId: itemData.id } : null), [itemData]);

  return { userParam, storeParam, productParam, pricesParam, tradeInParam, surveyItemParam };
};
