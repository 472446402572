import { ComponentProps, ReactNode, forwardRef } from 'react';

import { tv } from 'tailwind-variants';

import Typography from '@/components/typography';

const inputTv = tv({
  base: 'flex items-center w-full rounded border bg-none focus:border-black hover:cursor-text',
  variants: {
    hasError: {
      false: 'border-neutral-5',
      true: 'border-error',
    },
  },
  defaultVariants: {
    hasError: false,
  },
});

type InputWithLabelProps = {
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  errorMessage?: string;
  children?: ReactNode;
  classNameLabel?: string;
} & ComponentProps<'input'>;

const InputWithLabel = forwardRef<HTMLInputElement, InputWithLabelProps>(function InputLabel(
  { label, placeholder, className, isRequired = false, errorMessage, children, classNameLabel = '', ...props },
  ref
) {
  return (
    <div className={`flex flex-col ${className}`}>
      {label && (
        <label className={`pb-1 text-sm ${classNameLabel}`}>
          {label}
          {isRequired && '*'}
        </label>
      )}

      <div className={inputTv({ hasError: !!errorMessage })}>
        <input
          ref={ref}
          className="disabled:text-on-surface-light-medium-emphasis w-full bg-none px-4 py-2 placeholder:text-base placeholder:text-on-surface-light-low disabled:cursor-not-allowed disabled:bg-neutral-3"
          placeholder={placeholder}
          {...props}
        />
        {children && <div className="px-2">{children}</div>}
      </div>

      {errorMessage && (
        <Typography.Span data-testid="input-error" className="pt-1 text-error">
          {errorMessage}
        </Typography.Span>
      )}
    </div>
  );
});

export default InputWithLabel;
