export enum ROUTES {
  //Auth
  SEARCH = '/',
  LOGIN = '/login',
  SIGN_UP = '/sign-up',
  MAGIC_LINK_CONFIRMATION = '/sign-up/confirmation',
  FORGOT_PASSWORD = '/forgot-password',
  ONLY_ADMIN = '/only-admin',
  SSO_CALLBACK = '/sign-up/sso-callback',

  //Operator
  OPERATOR_PORTAL = '/operator-portal',
  OPERATOR_PORTAL_INVITE = '/operator-portal/invite',
  OPERATOR_PORTAL_DASHBOARD = '/operator-portal/dashboard',
  OPERATOR_PORTAL_TRADE_INS = '/operator-portal/my-trade-ins',

  //Grading
  GRADING_SELECT_SPECS = '/grading/select-specs',
  GRADING_QUESTIONS = '/grading/questions',
  GRADING_CHECK_IMEI = '/grading/check-imei',
  GRADING_PHONE_PHOTOS = '/grading/phone-photos',
  GRADING_NEW_PRODUCT_BUY = '/grading/new-product-buy',
  GRADING_NEW_PRODUCT_BUY_PRICE = '/grading/new-product-buy-price',
  GRADING_NEW_PRODUCT_SELECT_SPECS = '/grading/new-product-select-specs',
  GRADING_SUMMARY = '/grading/summary',
  GRADING_CUSTOMER_FORMS = '/grading/customer-forms',
  GRADING_PRICING_ANNOUNCEMENT = '/grading/pricing-announcement',

  //Functional Grading
  FUNCT_GRADING_STEP_BY_STEP = '/functional-grading/step-by-step',
  FUNCT_GRADING_RESULT = '/functional-grading/result',
  FUNCT_GRADING_SKIP = '/functional-grading/skip',

  //Cosmetic Grading
  AI_ANALYZING = '/ai-verification/analyzing',
  AI_DEFECT_DETECTION = '/ai-verification/defect-detected',

  //Trade-In
  TRADE_IN_CONTRACT = '/trade-in/contract',
  TRADE_IN_CONFIRM = '/trade-in/confirm',
  TRADE_IN_SUCCESS = '/trade-in/success',
  TRADE_IN_UNAVAILABLE = '/trade-in/unavailable',
  TRADE_IN_GIVE_UP = '/trade-in/give-up',
  TRADE_IN_DOWNGRADE = '/trade-in/downgrade',

  //Trade-In Auction
  TRADE_IN_AUCTION = '/trade-in/auction',
  TRADE_IN_AUCTION_SUCCESS = '/trade-in/auction/success',
  TRADE_IN_AUCTION_BUYER_NOT_FOUND = '/trade-in/auction/buyer-not-found',

  // Customer Trade-In
  CUSTOMER_SEARCH = '/customer',
  CUSTOMER_SELECT_SPECS = '/customer/select-specs',
  CUSTOMER_QUESTIONS = '/customer/questions',
  CUSTOMER_SUMMARY = '/customer/summary',
  CUSTOMER_CUSTOMER_FORMS = '/customer/customer-forms',
  CUSTOMER_SUCCESS = '/customer/success',
  CUSTOMER_TRADE_IN_UNAVAILABLE = '/customer/trade-in-unavailable',
  CUSTOMER_REFUSE_TRADE_IN = '/customer/refuse-tradein',

  // Session
  SESSION_SEARCH = '/session',
  SESSION_SELECT_SPECS = '/session/select-specs',
  SESSION_NOT_FOUND = '/session/not-found',
  SESSION_QUESTIONS = '/session/questions',
  SESSION_IMEI_CHECK = '/session/imei-check',
  SESSION_SUCCESS = '/session/success',

  //Others
  BLANK_PAGE = '/blank-page',
}

export enum PARAM_ORIGIN {
  PHONE_PHOTOS = 'phone-photos',
  TRADE_IN_DOWNGRADE = 'downgrade',
  FUNCTIONAL_DIAGNOSTIC = 'functional-diagnostic',
  DEFECT_DETECTED = 'defect-detected',
  AI_ANALYZING = 'ai-analyzing',
  NOT_ACCEPTED = 'not-accepted',
  LOGIN = 'login',
  COSMETIC = 'cosmetic',
  FUNCTIONAL = 'functional',
  SIGN_UP_CONFIRMATION = 'sign-up-confirmation',
  CONFIRM = 'confirm',
  SPECS = 'specs',
}

export const PUBLIC_ROUTES = [
  ROUTES.LOGIN,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.SIGN_UP,
  ROUTES.MAGIC_LINK_CONFIRMATION,
  ROUTES.CUSTOMER_SEARCH,
  ROUTES.CUSTOMER_SELECT_SPECS,
  ROUTES.CUSTOMER_SELECT_SPECS,
  ROUTES.CUSTOMER_QUESTIONS,
  ROUTES.CUSTOMER_SUMMARY,
  ROUTES.CUSTOMER_CUSTOMER_FORMS,
  ROUTES.CUSTOMER_SUCCESS,
  ROUTES.CUSTOMER_TRADE_IN_UNAVAILABLE,
  ROUTES.CUSTOMER_REFUSE_TRADE_IN,
  ROUTES.BLANK_PAGE,
];

export const OPERATOR_PORTAL_ROUTES = [
  ROUTES.OPERATOR_PORTAL,
  ROUTES.OPERATOR_PORTAL_INVITE,
  ROUTES.OPERATOR_PORTAL_DASHBOARD,
];
