import { BestBidResponse } from '@@/Pricing/services/bid-service';

export * from './format-currency';

export const getHighestStoreBoostPrice = (bidPrice?: BestBidResponse) =>
  bidPrice && bidPrice?.boosts && bidPrice.boosts.length > 0
    ? bidPrice.storePrice + Math.max(...bidPrice.boosts.map((boost) => boost.price ?? 0))
    : null;

export const getStoreBoostedPrice = (bestBidData: BestBidResponse | undefined, selectedBoost: string) => {
  if (!bestBidData || !selectedBoost) return null;

  const boost = getSelectedBoost(bestBidData, selectedBoost);
  const storeBoostedPrice = bestBidData.storePrice + (boost?.price ?? 0);
  const roundedStoreBoostedPrice = Number(storeBoostedPrice.toFixed(2));

  return roundedStoreBoostedPrice;
};

export const getSelectedBoost = (bestBidData: BestBidResponse | undefined, selectedBoost: string) =>
  bestBidData && bestBidData?.boosts && selectedBoost
    ? bestBidData.boosts.find((boost) => boost.sponsorshipId === selectedBoost)
    : undefined;
