import { type JSX, useMemo, useState } from 'react';

import { NextRouter } from 'next/router';

import { handleRedirectTradeIn } from '.';

import { USER_ROLE } from '@@/StoreAndStoreOperator/constants';
import { Store } from '@@/StoreAndStoreOperator/entities/store';

import { useEventsParams } from '@/hooks/use-events';

import { ROUTES } from '@/constants';

import { eventGa } from '@/utils';

import Typography from '@/components/typography';

import * as A from './assets';

type MenuHeaderProps = {
  store?: Store;
  router: NextRouter;
  logout: () => void;
};

type Item = {
  label: JSX.Element | string;
  onClick: () => Promise<boolean> | void;
  isEnable: boolean;
  icon: React.ReactNode;
  eventName: string;
};

const MenuHeader = ({ router, logout, store }: MenuHeaderProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const isAdmin = store?.membership?.role === USER_ROLE.STORE_OPERATOR_ADMIN;
  const isOperatorPortalRoute = router.pathname.includes(ROUTES.OPERATOR_PORTAL);

  const items = useMemo(
    () => [
      {
        label: 'Reiniciar Trade-in',
        onClick: () => handleRedirectTradeIn(router, ROUTES.SEARCH),
        isEnable: !isOperatorPortalRoute,
        icon: <A.RestartIcon />,
        eventName: 'Restart Trade In',
      },
      {
        label: 'Meus Trade-ins',
        onClick: () => handleRedirectTradeIn(router, ROUTES.OPERATOR_PORTAL_TRADE_INS),
        isEnable: true,
        icon: <A.TradeInIcon />,
        eventName: 'Meus Trade Ins',
      },
      {
        label: 'Desempenho da Loja',
        onClick: () => handleRedirectTradeIn(router, ROUTES.OPERATOR_PORTAL_DASHBOARD),
        isEnable: isAdmin,
        icon: <A.PerformanceIcon />,
        eventName: 'Manager Dashboard',
      },
      {
        label: 'Convidar Operador',
        onClick: () => handleRedirectTradeIn(router, ROUTES.OPERATOR_PORTAL_INVITE),
        isEnable: isAdmin,
        icon: <A.InviteOperator />,
        eventName: 'Invite Operator',
      },
      {
        label: 'Trocar de Loja',
        onClick: () => handleRedirectTradeIn(router, ROUTES.OPERATOR_PORTAL),
        isEnable: true,
        icon: <A.ChangeStore />,
        eventName: 'Change Store',
      },
      {
        label: 'Sair',
        onClick: () => logout(),
        isEnable: true,
        icon: <A.LogoutIcon />,
        eventName: 'Exit',
      },
    ],
    []
  );

  return (
    <div>
      {isOpen && (
        <div className="fixed left-0 top-0 h-screen w-screen">
          <button className="h-full w-full" onClick={() => setIsOpen(false)} />
        </div>
      )}

      <div className="relative">
        <button onClick={() => setIsOpen(!isOpen)}>
          <A.HamburgerIcon />
        </button>

        {isOpen && (
          <div
            className="absolute right-0 top-7 z-20 rounded-md border border-neutral-5 bg-white shadow-lg"
            style={{ boxShadow: '0px 8px 12px 0px rgba(0, 0, 0, 0.15), 0px 0px 1px 0px rgba(0, 0, 0, 0.31)' }}>
            {items.map((item, index) => (
              <HeaderOption key={item.label.toString() + index} item={item} role={store?.membership?.role} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const HeaderOption = ({ item, role }: { item?: Item; role?: string }) => {
  const { userParam, storeParam } = useEventsParams();

  if (!item || !item.isEnable) return null;

  return (
    <button
      onClick={() => {
        eventGa('storeapp_header_button_clwb', { ...userParam, ...storeParam, role: role, option: item.eventName });
        item.onClick();
      }}
      className="flex w-full items-center gap-2 px-4 py-2 text-left hover:bg-neutral-3">
      {item.icon}
      <Typography.Span variant="small-body" className="whitespace-nowrap font-normal capitalize">
        {item.label}
      </Typography.Span>
    </button>
  );
};

export default MenuHeader;
